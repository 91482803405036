.modal, div.logo {
    transition: opacity 0.25s ease;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}
a.anchor {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}
